body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Barokah';
  src: local('Barokah'), url(../fonts/barokah-signature/Barokah-Signature-by-Alifinart-Studio.ttf) format('truetype');
}

@font-face {
  font-family: 'PWManuel';
  src: local('PWManuel'), url(../fonts/pwmanuelfree/PWManuelfree.ttf) format('truetype');
}

@font-face {
  font-family: 'Famulred';
  src: local('Famulred'), url(../fonts/famulred/Famulred.otf) format('opentype');
}