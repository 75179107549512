.tooltip-wrapper {
  position: relative;
}

.tooltip-content.top-middle {
  bottom: 120%; /* Position above the element */
  left: 50%;
  max-width: 400px;
  min-width: 200px;
}

.tooltip-content.bottom-middle {
  top: 120%; /* Position below the element */
  left: 50%;
}

.tooltip-content {
  position: absolute;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 1031;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
  pointer-events: none;
  text-wrap: auto;
}

.tooltip-wrapper:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}

.tooltip-content .secondary {
  color: #bababa;
}

.tooltip-wrapper .col-sm-9 {
  padding-right: 0px !important;
  padding-left: 0px !important;
  width: 90px;
}

.tooltip-wrapper .col-sm-3 {
  /* padding-right: 0px !important; */
  padding-left: 0px !important;
}

.history-label {
  color: #8aa4af;
  font-size: small;
  font-weight: 600;
}

.history-label.automation {
  color: #4f8666;
}

.change-icon {
  width: 28px;
  padding-bottom: 2px;
}

div.tooltip-wrapper > div.row > div > img {
  width: 28px;
}

.history-row {
  display: flex;
  align-items: center;
}

div.history-row.row {
  padding: 0px;
  min-height: 0px !important;
}